<template>
  <div class="courier-others">
    <title-bar title="Others" />
    <div class="courier-others-settings">
      <div class="courier-others-settings-item">
        <b-form-checkbox v-model="onOff" size="lg" switch>
          <span>Availability</span>
        </b-form-checkbox>
      </div>
      <div class="courier-others-settings-item">
        <a href="tel:+4367761944466">Call dispatching</a>
      </div>
      <div class="courier-others-settings-item">
        <router-link to="/logout">Log out</router-link>
      </div>
    </div>
  </div>
</template>

<script>
import { BFormCheckbox } from 'bootstrap-vue'
import TitleBar from '@/components/TitleBar.vue'
import Toast from '@/components/Toast.vue'

export default {
  components: {
    TitleBar,
    BFormCheckbox,
  },
  data() {
    return {
      userData: JSON.parse(localStorage.getItem('userData')),
      onOff: this.$store.state.onOff,
      checked: false,
    }
  },
  watch: {
    onOff(value) {
      this.$Users
        .updateResourceByUrl({
          url: this.userData['@id'],
          body: { onOff: value },
        })
        .then(response => {
          if (response.status === 200) {
            this.$store.dispatch('setCourierVisibility', value)

            // clear GPS if onOff === false
            if (!value) {
              this.$Cars.updateResourceByUrl({
                url: this.$store.state.car,
                body: { latitude: '', longitude: '' },
              })
            }
          }
        })
        .catch(() => {
          this.$helper.showToast(
            this.$toast,
            Toast,
            'Unable to set availability',
            '',
            'danger',
          )
        })
    },
  },
  methods: {},
}
</script>
